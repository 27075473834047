import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { authenticatedRequest, KcApiError } from "../oidc";
import { AxiosError, AxiosResponse } from "axios";

export const deleteSession = async (id: string) => {
  return authenticatedRequest(`/account/sessions/${id}`, {
    method: "DELETE",
  });
};

export const useDeleteSession = (
  config: UseMutationOptions<
    AxiosResponse<{ id: string }, string>,
    AxiosError<KcApiError, string>,
    string
  >
) => {
  return useMutation({ ...config, mutationFn: deleteSession });
};

export default useDeleteSession;
