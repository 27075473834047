import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { CustomContentProps } from "notistack";
import React, { memo } from "react";

type SnackbarVariantIconProps = {
  variant: CustomContentProps["variant"];
};

const SnackbarVariantIcon = memo(({ variant }: SnackbarVariantIconProps) => {
  switch (variant) {
    case "success":
      return <CobaltIcon name="check-fill" color="cobalt.greenIcon" />;
    case "error":
      return <CobaltIcon name="x-fill" color="cobalt.redIcon" />;
    case "warning":
      return <CobaltIcon name="alert-triangle" color="cobalt.orangeIcon" />;
    case "info":
    default:
      return <CobaltIcon name="info-fill" color="cobalt.dodgerBlueIcon" />;
  }
});

export default SnackbarVariantIcon;
