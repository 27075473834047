import { KcContextCustom } from "../kcContext.d";
import React, { memo } from "react";
import {
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { PageTitle } from "../components/custom/title/PageTitle";
import { PageProps } from "../types/pages";
import { useUpdateAccount } from "../services";
import { useKcMessagesContext } from "../contexts/KcMessagesContext";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { MainTextField, rEmail } from "@docaposte-agility/da-keycloakify";

type AccountProps = PageProps<KcContextCustom.Account>;

const Account = memo(({ kcContext }: AccountProps) => {
  const { account, locale, realm } = kcContext;
  const { msgStr, advancedMsgStr } = useKcMessagesContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const { mutate: updateAccount } = useUpdateAccount({
    onSuccess: () => {
      enqueueSnackbar(msgStr("accountUpdatedMessage"), { variant: "success" });
    },
    onSettled: () => {
      setIsSubmitting(false);
    },
    onError: (error) => {
      error.response?.data.errors.map((kcError) =>
        enqueueSnackbar(advancedMsgStr(kcError.errorMessage), {
          variant: "error",
        })
      );
    },
  });

  const handleAccountUpdate = (formData: FormData): void => {
    const locale = formData.get("locale")?.toString();
    formData.delete("locale");

    updateAccount({
      ...account,
      ...Object.fromEntries(formData.entries()),
      attributes: {
        ...account.attributes,
        locale: locale || "en",
      },
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    handleAccountUpdate(formData);
  };

  const formFields: Array<keyof KcContextCustom.Account["account"]> = [
    "username",
    "email",
    "firstName",
    "lastName",
  ];

  const { control } = useForm();

  return (
    <Grid container direction="column">
      <PageTitle message="viewProfile" />
      <Grid
        container
        component={Paper}
        direction="column"
        margin="auto"
        padding="24px"
        width="fit-content"
      >
        <Typography variant="h2" paddingBottom="16px">
          {advancedMsgStr("editAccountHtmlTitle")}
        </Typography>
        <Grid
          container
          component="form"
          method="post"
          onSubmit={handleSubmit}
          direction="column"
          spacing={2}
          alignItems="center"
        >
          {formFields.map((field) => (
            <Grid item key={field} display="flex" direction="column">
              <FormLabel>{advancedMsgStr(field)}</FormLabel>
              <MainTextField
                name={field}
                defaultValue={account[field]}
                disabled={field === "username"}
                sx={{ maxWidth: "500px", width: "60vw" }}
                control={control}
                rules={
                  field === "email"
                    ? {
                        pattern: {
                          value: rEmail,
                          message: msgStr("requiredCorrectEmail"),
                        },
                      }
                    : {}
                }
              />
            </Grid>
          ))}
          {realm.internationalizationEnabled && locale && (
            <Grid
              item
              key="locale"
              display="flex"
              direction="column"
              width="100%"
            >
              <FormLabel id="kc-locale-label">{msgStr("language")}</FormLabel>
              <Select
                labelId="kc-locale-label"
                name="locale"
                type="input"
                fullWidth
                defaultValue={locale.currentLanguageTag}
              >
                {locale.supported.map((language) => (
                  <MenuItem value={language.languageTag}>
                    {language.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item width="100%">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              disableRipple
            >
              {advancedMsgStr("doSave")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Account;
