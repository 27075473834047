import {
  ProviderImageProps,
  ProviderImage as Image,
} from "@docaposte-agility/da-keycloakify";
import config from "../../../config.json";

export const ProviderImage = (props: ProviderImageProps) => {
  if ((props.provider as any)?.alias === config.providerLinIconName) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="24"
        id="lin-icon"
      >
        <path
          fill="#00F"
          fillRule="evenodd"
          d="M9.877 19.045V6.433H6V19.42c0 2.505 2.106 4.563 4.716 4.581H18.1v-3.369H11.53c-.914 0-1.654-.71-1.654-1.586z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#00F"
          fillRule="evenodd"
          d="M21.284 6.433H13.9V18.46h3.746V9.802h2.822c.914 0 1.654.71 1.654 1.587V24H26V11.014c0-2.504-2.106-4.563-4.716-4.58zM15.762 4.454C16.994 4.454 18 3.446 18 2.227 18 1.007 16.994 0 15.762 0S13.55 1.007 13.55 2.227s.98 2.227 2.212 2.227z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }
  return <Image {...props} />;
};
