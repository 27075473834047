import {
  ProviderButtonProps,
  ProviderButton as Button,
} from "@docaposte-agility/da-keycloakify";
import { useMediaQuery } from "@mui/material";
import config from "../../../config.json";

const colors = {
  backgroundColor: config.providerLinColor,
  border: "2px solid",
  borderColor: config.providerLinColorBorder,
};
const hoverColors = {
  backgroundColor: config.providerHoverLinColor,
  border: "2px solid",
  borderColor: config.providerHoverLinColorBorder,
};

export default function ProviderButton(props: ProviderButtonProps) {
  const matchesSmDown = useMediaQuery("(max-width:770px)");

  if (props.provider.alias === config.providerLinIconName) {
    return (
      <Button
        {...props}
        sx={{
          ...colors,
          "&:hover": hoverColors,
          "&:focus": hoverColors,
          "&:active": hoverColors,
          mx: matchesSmDown ? 0 : 1,
          mt: 2,
        }}
        id="social-lin"
      />
    );
  }

  return <Button {...props} fullWidth sx={{ mx: matchesSmDown ? 0 : 2 }} />;
}
