import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { PageProps } from "../types/pages";

type ApplicationsProps = PageProps<KcContextCustom.Applications>;

const Applications = memo(({ kcContext }: ApplicationsProps) => {
  const { url } = kcContext;

  React.useEffect(() => {
    kcContext.applications.applications = [];
    window.location.href = url.accountUrl;
  });

  return <></>;
});

export default Applications;
