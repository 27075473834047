import {
  DaKcContextType,
  KcCustomPage,
} from "@docaposte-agility/da-keycloakify";
import { createGetKcContext } from "keycloakify/login";

export const ExtraPages: Array<KcCustomPage> = [
  { pageId: "login-update-password.ftl" },
  { pageId: "webauthn-authenticate.ftl" },
  { pageId: "webauthn-error.ftl" },
  { pageId: "webauthn-register.ftl" },
  { pageId: "update-user-profile.ftl" },
  { pageId: "delete-account-confirm.ftl" },
  { pageId: "idp-review-user-profile.ftl" },
  { pageId: "secret-question-config.ftl" },
  { pageId: "secret-question.ftl" },
  { pageId: "accept-terms-and-conditions.ftl" },
  { pageId: "otp-sms.ftl" },
  { pageId: "da-otp-mail-page.ftl" },
];

export const { getKcContext } = createGetKcContext<DaKcContextType>();

export const { kcContext } = getKcContext() || {};

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
