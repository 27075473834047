import {
  BasicHeader,
  BasicHeaderProps,
} from "@docaposte-agility/da-keycloakify";
import { Typography } from "@mui/material";
import { KcContext, kcContext } from "../../kcContext";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

export const Header = (props: BasicHeaderProps) => {
  const anyKcContext = kcContext as KcContext;
  switch (anyKcContext.pageId) {
    case "login-otp.ftl":
      return (
        <>
          <BasicHeader
            {...props}
            message={
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  justifyContent: "left",
                }}
                color="cobalt.bleuDigital100"
              >
                {props.message}
                <CobaltIcon name="arrow-right" />
              </Typography>
            }
          />
        </>
      );
    default:
      return <BasicHeader {...props} />;
  }
};
