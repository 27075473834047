import { theme } from "@docaposte-agility/da-design-system";
import { Box, Divider, Typography, TypographyProps } from "@mui/material";
import { CustomMessageKey } from "../../../messages/CustomMessages";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";
import { memo } from "react";

type PageTitleProps = TypographyProps & {
  message: CustomMessageKey;
};

export const PageTitle = memo(({ message, ...props }: PageTitleProps) => {
  const { msgStr } = useKcMessagesContext();

  return (
    <Box id="kc-page-title" paddingTop={3} paddingBottom={4}>
      <Typography
        variant="h1"
        sx={{ color: "cobalt.bleuDigital100" }}
        {...props}
      >
        {msgStr(message)}
      </Typography>
      <Divider
        orientation="horizontal"
        sx={{ paddingTop: "8px", color: theme.palette?.divider }}
      />
    </Box>
  );
});
