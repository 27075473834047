import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import LinkedAccountsDataDisplay from "../components/custom/data/LinkedAccountDataDisplay";
import { PageTitle } from "../components/custom/title/PageTitle";
import { PageProps } from "../types/pages";

type FederatedIdentityProps = PageProps<KcContextCustom.FederatedIdentity>;

const FederatedIdentity = memo(({ kcContext }: FederatedIdentityProps) => {
  const { federatedIdentity, url, stateChecker } = kcContext;

  const { breakpoints } = useTheme();
  const matchesSmDown = useMediaQuery(breakpoints.down("sm"));

  return (
    <Grid container direction="column">
      <PageTitle message="linkedAccountsHtmlTitle" />
      <Grid
        container
        direction={matchesSmDown ? "column" : "row"}
        gap={3}
        sx={{ wrap: "wrap" }}
      >
        {federatedIdentity.identities.map((identity) => (
          <LinkedAccountsDataDisplay
            identity={identity}
            socialUrl={url.socialUrl}
            stateChecker={stateChecker}
            removable={federatedIdentity.removeLinkPossible}
          />
        ))}
      </Grid>
    </Grid>
  );
});

export default FederatedIdentity;
