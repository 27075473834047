import { fr_messages as defaultFrMessages } from "@docaposte-agility/da-keycloakify";
import { CustomMessages } from "./CustomMessages";

export const fr_messages: CustomMessages = {
  ...defaultFrMessages,
  requiredFields: "Champs requis",
  loginTitle: "Connexion",
  logoutOtherSessions: "Se déconnecter des autres appareils",
  "identity-provider-login-label": "Ou connectez-vous avec :",
  doRegister: "S'enregistrer",
  backToLogin: "Retour à la connexion",
  backToApplication: "Revenir à l'application",
  doClickHere: "Cliquez ici",
  loginTotpScanBarcode: "Scanner le QR code",
  allRightsReserved: "Certinomis © Tous droits réservés",
  "password-display-name": "Mot de passe",
  "password-help-text": "Connectez-vous en entrant votre mot de passe.",

  // Custom Messages
  forgotPasswordInfo:
    "Entrez votre adresse email ; un courriel va vous être envoyé vous permettant de créer un nouveau mot de passe.",

  "da-magic-link-authenticator-display-name":
    "Authentification par lien magique",
  "da-magic-link-authenticator-help-text":
    "Vous allez recevoir un courriel avec un lien pour vous connecter.",
  "da-otp-mail-authenticator-display-name": "OTP par courriel",
  "da-otp-mail-authenticator-help-text":
    "Entrez un code de vérification envoyé à votre adresse email.",
  "da-webauthn-wr-authenticator-display-name":
    "Clé de sécurité pré-enregistrée",
  "da-webauthn-wr-authenticator-help-text":
    "Utilisez une clé de sécurité pour vous connecter.",
};

export default fr_messages;
