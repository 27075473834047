import { KcContext } from "../kcContext";
import { CustomMessageKey } from "../messages/CustomMessages";

export type HeaderLink = {
  href: string;
  label: CustomMessageKey;
  selected: boolean;
  shouldRender: boolean;
};

export const useHeaderLinks = (kcContext: KcContext): Array<HeaderLink> => {
  const { url, pageId, features } = kcContext;
  const { identityFederation, passwordUpdateSupported, log } = features;

  return [
    {
      href: url.accountUrl,
      label: "personalInfoSidebarTitle",
      selected: pageId === "account.ftl",
      shouldRender: true,
    },
    {
      href: url.passwordUrl,
      label: "credentials",
      selected: pageId === "password.ftl",
      shouldRender: passwordUpdateSupported,
    },
    {
      href: url.logUrl,
      label: "deviceActivitySidebarTitle",
      selected: pageId === "log.ftl",
      shouldRender: log,
    },
    {
      href: url.sessionsUrl,
      label: "sessions",
      selected: pageId === "sessions.ftl",
      shouldRender: true,
    },
    {
      href: url.socialUrl,
      label: "linkedAccountsSidebarTitle",
      selected: pageId === "federatedIdentity.ftl",
      shouldRender: identityFederation,
    },
  ];
};
