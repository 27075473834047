import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import React from "react";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";
import SnackbarVariantTitle from "./SnackbarVariantTitle";

type AccountSnackbarProps = CustomContentProps;

const AccountSnackbar = React.forwardRef<HTMLDivElement, AccountSnackbarProps>(
  (props: AccountSnackbarProps, forwardedRef) => {
    const { id, message, variant } = props;
    const { msgStr } = useKcMessagesContext();
    const { closeSnackbar } = useSnackbar();

    const theme = useTheme();
    const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <SnackbarContent ref={forwardedRef}>
        <Grid
          container
          component={Paper}
          square
          boxShadow={2}
          padding="16px"
          width={matchesSmDown ? "100%" : "500px"}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
            xs={12}
          >
            <SnackbarVariantTitle
              variant={variant}
              message={msgStr(`${variant}SnackBarTitle`)}
            />
            <IconButton
              onClick={() => closeSnackbar(id)}
              disableRipple
              sx={{ color: "cobalt.ultramarine" }}
            >
              <CobaltIcon name="x" />
            </IconButton>
          </Grid>
          <Grid item component={Typography} variant="body1" xs={12}>
            {message}
          </Grid>
        </Grid>
      </SnackbarContent>
    );
  }
);

export default AccountSnackbar;
