import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { Box, Stack, Typography } from "@mui/material";
import CredentialDataDisplay from "../components/custom/data/CredentialDataDisplay";
import { WaitingScreen } from "@docaposte-agility/da-keycloakify";
import { PageTitle } from "../components/custom/title/PageTitle";
import { PageProps } from "../types/pages";
import { useGetCredentials } from "../services";
import { useKcMessagesContext } from "../contexts/KcMessagesContext";

type PasswordProps = PageProps<KcContextCustom.Password>;

export const Password = memo(({ kcContext }: PasswordProps) => {
  const { msgStr } = useKcMessagesContext();

  const credentials = useGetCredentials();

  return (
    <Stack direction="column">
      <PageTitle message="accountCredentialsHtmlTitle" />
      {credentials.isLoading ? (
        <WaitingScreen />
      ) : (
        <>
          <Box id="basic-authentication-credentials" marginBottom={3}>
            <Typography variant="h2" marginBottom={2}>
              {msgStr("basicAuthenticationHtmlTitle")}
            </Typography>
            {credentials.isSuccess &&
              credentials.data
                .filter((c) => c.category === "basic-authentication")
                .map((cred) => <CredentialDataDisplay credential={cred} />)}
          </Box>
          <Box id="two-factor-credentials">
            <Typography variant="h2" marginBottom={2}>
              {msgStr("twoFactorAuthenticationHtmlTitle")}
            </Typography>
            {credentials.isSuccess &&
              credentials.data
                .filter((c) => c.category === "two-factor")
                .map((cred) => (
                  <CredentialDataDisplay credential={cred} marginBottom={2} />
                ))}
          </Box>
        </>
      )}
    </Stack>
  );
});

export default Password;
