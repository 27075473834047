import { en_messages as defaultEnMessages } from "@docaposte-agility/da-keycloakify";
import { CustomMessages } from "./CustomMessages";

export const en_messages: CustomMessages = {
  ...defaultEnMessages,
  requiredFields: "Required field",
  loginTitle: "Connection",
  logoutOtherSessions: "Disconnect from other devices",
  "identity-provider-login-label": "Or log in with :",
  doRegister: "Register",
  doClickHere: "Click here",
  backToLogin: "Back to connection",
  backToApplication: "Back to application",
  allRightsReserved: "Certinomis © All rights reserved",

  // Custom Messages
  forgotPasswordInfo:
    "Enter your email address; an email will be sent to you allowing you to create a new password.",

  "da-magic-link-authenticator-display-name": "Magic link authentication",
  "da-magic-link-authenticator-help-text":
    "You will receive an email with a link to log in.",
  "da-otp-mail-authenticator-display-name": "OTP by email",
  "da-otp-mail-authenticator-help-text":
    "Enter a verification code sent to your email address.",
  "da-webauthn-wr-authenticator-display-name": "Pre-registered security key",
  "da-webauthn-wr-authenticator-help-text": "Use a security key to log in.",
};

export default en_messages;
