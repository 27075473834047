import React from "react";
import { AppBar, Grid, Paper } from "@mui/material";
import { memo } from "react";
import { KcContext } from "../../../kcContext";
import { ProfileMenu } from "./ProfileMenu";
import LogoContainer from "../logo/LogoContainer";
import { useHeaderLinks } from "../../../hooks/useHeaderLinks";
import PageLink from "./PageLink";

type DesktopHeaderProps = {
  kcContext: KcContext;
};

const DesktopHeader = memo(({ kcContext }: DesktopHeaderProps) => {
  const { account, url } = kcContext;
  const links = useHeaderLinks(kcContext);

  return (
    <Grid
      container
      component={AppBar}
      position="sticky"
      top={0}
      sx={{ background: "unset" }}
    >
      <Grid
        container
        component={Paper}
        square
        direction="row"
        alignItems="center"
        width="100%"
        padding="16px"
      >
        <LogoContainer
          src={require("../../../assets/img/logo-certinomis.png")}
          alt="Logo certinomis"
          xs={2}
        />
        <Grid item component="nav" xs={8}>
          {links
            .filter((link) => link.shouldRender)
            .map((link) => (
              <PageLink
                href={link.href}
                message={link.label}
                selected={link.selected}
              />
            ))}
        </Grid>
        <ProfileMenu account={account} url={url} xs={2} />
      </Grid>
    </Grid>
  );
});

export default DesktopHeader;
