import { createUseI18n } from "keycloakify/account";
import { CustomMessageKey } from "./CustomMessages";
import en_messages from "./en_messages";
import fr_messages from "./fr_messages";

export const { useI18n } = createUseI18n<CustomMessageKey>({
  en: en_messages,
  fr: fr_messages,
});

export type I18n = ReturnType<typeof useI18n>;
