import { Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import SnackbarVariantIcon from "./SnackbarVariantIcon";
import { CustomContentProps } from "notistack";

type SnackbarVariantTileProps = {
  variant: CustomContentProps["variant"];
  message?: string;
};

const SnackbarVariantTitle = memo(
  ({ variant, message }: SnackbarVariantTileProps) => {
    return (
      <Grid container xs={10} alignItems="center">
        <Grid item xs={1}>
          <SnackbarVariantIcon variant={variant} />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h5">{message}</Typography>
        </Grid>
      </Grid>
    );
  }
);

export default SnackbarVariantTitle;
