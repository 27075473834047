import React, { memo } from "react";
import { KcContext } from "../kcContext";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import DesktopHeader from "../components/custom/header/DesktopHeader";
import MobileHeader from "../components/custom/header/MobileHeader";
import { SnackbarProvider } from "notistack";
import AccountSnackbar from "../components/custom/snackbar/AccountSnackbar";

type TemplateProps = {
  kcContext: KcContext;
  children: React.ReactNode;
};

export const Template = memo(({ kcContext, children }: TemplateProps) => {
  const { breakpoints } = useTheme();
  const matchesSmDown = useMediaQuery(breakpoints.down("sm"));

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={5000}
      dense={matchesSmDown}
      Components={{
        default: AccountSnackbar,
        info: AccountSnackbar,
        success: AccountSnackbar,
        warning: AccountSnackbar,
        error: AccountSnackbar,
      }}
    >
      {matchesSmDown ? (
        <MobileHeader kcContext={kcContext} />
      ) : (
        <DesktopHeader kcContext={kcContext} />
      )}
      <Grid
        container
        component="main"
        direction="column"
        spacing={2}
        width="80vw"
        margin="auto"
      >
        {children}
      </Grid>
    </SnackbarProvider>
  );
});

export default Template;
