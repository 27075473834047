import {
  BasicHeaderProps,
  ComponentsContextProps,
  KcDividerProps,
  KcProviderDividerProps,
  MainSubmitButtonProps,
  PasswordProfileTextFieldProps,
  PasswordTextFieldProps,
  ProviderButtonProps,
  ProviderImageProps,
  SecondaryButtonProps,
} from "@docaposte-agility/da-keycloakify";
import { Link } from "./common/Link";
import { SubmitButton } from "./common/SubmitButton";
import { Box } from "@mui/material";
import { SecondaryButton } from "./common/SecondaryButton";
import { Divider } from "./common/Divider";
import { Header } from "./common/Header";
import PasswordTextField from "./common/PasswordTextField";
import PasswordProfileTextField from "./common/PasswordProfileTextField";
import { Switch } from "./common/Switch";
import { ProviderImage } from "./common/ProviderImage";
import ProviderButton from "./common/ProviderButton";
import { ProviderDivider } from "./common/ProviderDivider";

export default function ApplyComponent(
  setters: ComponentsContextProps,
  messages: any
) {
  const {
    setCustomLink,
    setCustomSubmitButton,
    setCustomSecondaryButton,
    setCustomInfoDivider,
    setCustomDivider,
    setCustomBasicHeader,
    setCustomPasswordTextField,
    setCustomPasswordProfileTextField,
    setCustomSwitch,
    setCustomProviderImage,
    setCustomProviderButton,
    setCustomProviderDivider,
  } = setters;
  const { msgStr } = messages;

  setCustomLink(() => (props: any) => <Link {...props} />);

  setCustomSubmitButton(() => (props: MainSubmitButtonProps) => (
    <SubmitButton {...props} />
  ));

  setCustomSecondaryButton(() => (props: SecondaryButtonProps) => (
    <SecondaryButton {...props} />
  ));

  setCustomDivider(() => (props: KcDividerProps) => <Divider {...props} />);

  setCustomProviderDivider(() => (props: KcProviderDividerProps) => (
    <Divider {...props} message={msgStr("or").toUpperCase()} />
  ));

  setCustomInfoDivider(() => (props: KcDividerProps) => (
    <Box my={4} mx={7}>
      <ProviderDivider message={msgStr("or").toUpperCase()} {...props} />
    </Box>
  ));

  setCustomBasicHeader(() => (props: BasicHeaderProps) => (
    <Header {...props} />
  ));

  setCustomPasswordTextField(() => (props: PasswordTextFieldProps) => (
    <PasswordTextField {...props} />
  ));

  setCustomPasswordProfileTextField(
    () => (props: PasswordProfileTextFieldProps) =>
      <PasswordProfileTextField {...props} />
  );
  setCustomSwitch(() => (props: any) => <Switch {...props} />);

  setCustomProviderImage(() => (props: ProviderImageProps) => (
    <ProviderImage {...props} />
  ));

  setCustomProviderButton(() => (props: ProviderButtonProps) => (
    <ProviderButton {...props} />
  ));
}
