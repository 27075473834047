import React, { memo } from "react";
import {
  Button,
  Grid,
  RegularBreakpoints,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { KcContextCustom } from "../../../kcContext.d";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

interface ProfileMenuProps extends RegularBreakpoints {
  account: KcContextCustom.Account["account"];
  url: KcContextCustom.Account["url"];
}

export const ProfileMenu = memo(
  ({ account, url, ...breakpoints }: ProfileMenuProps) => {
    const { msgStr } = useKcMessagesContext();

    const theme = useTheme();
    const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const fullName = account.firstName + " " + account.lastName;

    return (
      <Grid
        item
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="center"
        {...breakpoints}
      >
        <Typography color="cobalt.ultramarine">{fullName}</Typography>
        <Button
          variant="text"
          disableRipple
          onClick={() => (window.location.href = url.logoutUrl)}
          startIcon={<CobaltIcon name="log-out" />}
        >
          {!matchesMdDown && msgStr("doLogout")}
        </Button>
      </Grid>
    );
  }
);
