import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ThemeConfiguration } from "../../types/theme-config";

const getConfig = async (): Promise<ThemeConfiguration> => {
  const response = await axios.get("/static/certinomis/account.json");

  return response.data;
};

export const useGetConfig = () =>
  useQuery({
    queryKey: ["theme-configuration"],
    queryFn: getConfig,
    retry: 2,
  });

export default useGetConfig;
