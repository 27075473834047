import { useKcMessagesContext } from "@docaposte-agility/da-keycloakify";
import { Box, Typography } from "@mui/material";

export const FooterInfo = (props: any) => {
  const { kcContext } = props;
  const { pageId } = kcContext;
  const { advancedMsg } = useKcMessagesContext();

  const getInfo = () => {
    switch (pageId) {
      case "login-reset-password.ftl":
        return (
          <Typography variant="body2" color="cobalt.lynch" align="center">
            {advancedMsg("forgotPasswordInfo")}
          </Typography>
        );
      default:
        return undefined;
    }
  };

  if (getInfo()) {
    return <Box mt={3}>{getInfo()}</Box>;
  }
  return <></>;
};
