import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { KcContextCustom } from "../../kcContext.d";
import { authenticatedRequest, KcApiError } from "../oidc";
import { AxiosError, AxiosResponse } from "axios";

type UserAccount = KcContextCustom.Common["account"];

const updateAccount = async (account: UserAccount) => {
  return authenticatedRequest("/account/", {
    method: "POST",
    data: account,
  });
};

export const useUpdateAccount = (
  config: UseMutationOptions<
    AxiosResponse<UserAccount, UserAccount>,
    AxiosError<KcApiError, UserAccount>,
    UserAccount
  >
) => {
  return useMutation({ ...config, mutationFn: updateAccount });
};

export default useUpdateAccount;
