import {
  Header,
  KcDrawerLanguage,
  useComponentsContext,
  useJsonContext,
  useKcLanguagesContext,
  useKcMessagesContext,
  TemplateProps as KcTemplateProps,
} from "@docaposte-agility/da-keycloakify";
import { CobaltIcon, ColbaltColor } from "@docaposte-agility/da-design-system";
import { memo, ReactNode, useEffect, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Link,
  Divider,
  Paper,
  Card,
  useTheme,
  Theme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { FooterInfo } from "../components/custom/FooterInfo";
import ApplyComponent from "../components/ApplyComponents";
import config from "../../config.json";
import { KcContext } from "../kcContext";

interface TemplateProps extends KcTemplateProps {
  children: ReactNode;
  kcContext: KcContext;
}

interface DATheme extends Theme {
  palette: Theme["palette"] & { cobalt: ColbaltColor };
}

export const Template = memo(
  ({ children, kcContext, ...props }: TemplateProps) => {
    const { locale, realm } = kcContext;

    // Chargement depuis les contextes
    const { changeLocale, getCurrentLanguageTag, getFlagFromTag, flags } =
      useKcLanguagesContext();
    const { json } = useJsonContext();
    const { defaultBackgroundName, serverCommonUrl } = json;
    const theme = useTheme() as DATheme;
    const matchesSmDown = useMediaQuery("(max-width:770px)");
    const isMobile = useMediaQuery("(max-width:500px)");

    // Variables
    const [showLanguage, setShowLanguage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Fonctions
    const onChangeLanguageClick = (languageTag: string) =>
      changeLocale(languageTag);

    const onLanguageClick = (language: {
      url: string;
      label: string;
      languageTag: string;
    }) => {
      onChangeLanguageClick(language.languageTag);
      window.location.href = language.url;
    };

    const setters = useComponentsContext();
    const messages = useKcMessagesContext();
    const { getAlert, getLink } = useComponentsContext();
    const { flagsExtension } = json;

    // if (messagesPerField.exists("otp")) {
    //   // Add "otp" field to custom field names
    // }
    //
    // if (messagesPerField.exists("password-new")) {
    //   // Add "password-new" field to custom field names
    // }

    // Chargement des composants customs
    useEffect(() => {
      ApplyComponent(setters, messages);
      setIsLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100vh" }}
        >
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Box>
        {locale !== undefined &&
          realm.internationalizationEnabled &&
          locale.supported?.length > 1 && (
            <KcDrawerLanguage
              supported={locale?.supported || []}
              onLanguageClick={onLanguageClick}
              open={showLanguage}
              setOpen={setShowLanguage}
            />
          )}
        <Box
          id="kc-header"
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "calc(100vh - 48px)",
            overflow: "hidden",
            background: `url(${serverCommonUrl}/${defaultBackgroundName}) center center no-repeat fixed`,
            backgroundSize: "cover",
            margin: 0,
            padding: 0,
            outline: 0,
            paddingBottom: matchesSmDown ? "24px" : "64px",
            paddingTop: "28px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            sx={
              matchesSmDown
                ? {
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
                : {
                    justifyContent: "center",
                    alignItems: "flex-end",
                    mr: 20,
                  }
            }
          >
            <Box
              sx={
                matchesSmDown
                  ? {}
                  : {
                      position: "fixed",
                      bottom: "85px",
                      left: "80px",
                    }
              }
            >
              <img
                src={require("../assets/img/logo-certinomis.png")}
                alt={"Certinomis"}
                width={128}
              />
            </Box>
            <Paper
              sx={
                matchesSmDown
                  ? isMobile
                    ? { width: "100%", p: 4, mt: 20 }
                    : { width: "500px", p: 4, mt: 20 }
                  : { width: "400px", p: 4 }
              }
            >
              {locale !== undefined &&
                realm.internationalizationEnabled &&
                locale.supported?.length > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Card
                      sx={{
                        p: 1,
                        gridGap: "8px",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid",
                        borderColor: theme.palette.cobalt.grey10,
                        width: "48px",
                        height: "24px",
                        alignItems: "center",
                        borderRadius: "4px",
                        cursor: "pointer",
                        mb: 1,
                      }}
                      onClick={() => setShowLanguage(true)}
                    >
                      <img
                        src={
                          flags[
                            `${getFlagFromTag(
                              getCurrentLanguageTag()
                            )}${flagsExtension}`
                          ]
                        }
                        alt=""
                        width="14px"
                      />
                      <Typography id="kc-locale-icon" variant="subtitle2">
                        <CobaltIcon
                          name="chevron-down"
                          color="cobalt.ultramarine"
                        />
                      </Typography>
                    </Card>
                  </Box>
                )}
              <Box>
                <Header kcContext={kcContext} titleProps={{ align: "left" }} />
              </Box>
              <Box my={2}>
                {getAlert({
                  message: kcContext.message!,
                  kcContext: kcContext,
                })}
              </Box>
              {children}
              {kcContext.auth?.showTryAnotherWayLink && (
                <Box paddingTop="16px">
                  <form
                    id="kc-select-try-another-way-form"
                    action={kcContext.url.loginAction}
                    method="post"
                  >
                    <input type="hidden" name="tryAnotherWay" value="on" />
                    {getLink({
                      href: "#",
                      id: "try-another-way",
                      onClick: () => {
                        (document.forms as { [key: string]: any })[
                          "kc-select-try-another-way-form"
                        ].submit();
                        return false;
                      },
                      children: messages.advancedMsgStr("doTryAnotherWay"),
                    })}
                  </form>
                </Box>
              )}
              <FooterInfo kcContext={kcContext} />
            </Paper>
          </Box>
        </Box>
        <AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            borderTop: "1px solid",
            borderColor: theme.palette.cobalt.grey10,
          }}
        >
          <Toolbar
            sx={{
              minHeight: "48px !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height: isMobile ? "auto" : "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Link target="_blank" underline="none" href={config.contactUrl}>
                <Typography variant="body2" fontSize="12px">
                  Contact
                </Typography>
              </Link>
              <Divider sx={{ mx: 1.5, height: 16 }} orientation="vertical" />
              <Typography variant="body2" fontSize="12px">
                {messages.advancedMsgStr("allRightsReserved")}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Link
                sx={{ mt: 0.5 }}
                href={config.techAndTrustUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../assets/img/footer-image.png")}
                  alt={"Logo Tech & Trust"}
                  height={"24px"}
                />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
);
