import { ThemeProvider } from "@emotion/react";
import { OidcProvider } from "../services/oidc";
import { theme } from "@docaposte-agility/da-design-system";
import { createTheme, CssBaseline } from "@mui/material";
import { I18n } from "../messages/i18n";
import { KcMessagesContextProvider } from "./KcMessagesContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

type ContextProviderProps = {
  i18n: NonNullable<I18n>;
  children: React.ReactNode | React.ReactNode[] | undefined;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error: any) => {
        if (failureCount > 2 || error?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

const ContextProvider = ({ i18n, children }: ContextProviderProps) => {
  const ddsThemeExtended = createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: theme.palette?.cobalt.light,
          },
        },
      },
    },
  });

  return (
    <OidcProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={ddsThemeExtended}>
          <KcMessagesContextProvider i18n={i18n}>
            <CssBaseline />
            {children}
          </KcMessagesContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </OidcProvider>
  );
};

export default ContextProvider;
