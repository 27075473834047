import { useKcMessagesContext } from "@docaposte-agility/da-keycloakify";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Typography, Link as MuiLink, LinkProps, Box } from "@mui/material";
import { KcContext, kcContext } from "../../kcContext";

export const Link = (props: LinkProps) => {
  const { children, ...others } = props;
  const { msg, msgStr } = useKcMessagesContext();
  const anyKcContext = kcContext as KcContext;

  if (
    anyKcContext.pageId === "register-user-profile.ftl" &&
    others.id === "backToApplication"
  ) {
    return (
      <Box
        mt={-1.5}
        display="flex"
        flexDirection="column"
        sx={{ gridGap: "16px" }}
      >
        <Typography variant="body2" color="cobalt.lynch">
          *{msg("requiredFields")}
        </Typography>
        <MuiLink underline="none" {...others}>
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              justifyContent: "left",
            }}
            color="cobalt.bleuDigital100"
          >
            <CobaltIcon name="arrow-left" />
            {children}
          </Typography>
        </MuiLink>
      </Box>
    );
  }

  const getChildren = (currentMsg: string) => {
    switch (currentMsg) {
      case msgStr("doClickHere"):
      case msgStr("loginTotpScanBarcode"):
      case msgStr("loginTotpUnableToScan"):
        return (
          <>
            {children}
            <CobaltIcon name="arrow-right" />
          </>
        );
      case msgStr("backToLogin"):
      case msgStr("backToApplication"):
        return (
          <>
            <CobaltIcon name="arrow-left" />
            {children}
          </>
        );
      default:
        return children;
    }
  };

  return (
    <MuiLink underline="none" {...others}>
      <Typography
        variant="h5"
        color="cobalt.bleuDigital100"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          gap: "6px",
        }}
      >
        {getChildren((children as any)?.props?.children as string)}
      </Typography>
    </MuiLink>
  );
};
