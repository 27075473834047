import { createGetKcContext } from "keycloakify/account";
import { KcContextAccountType } from "./kcContext.d";

export const { getKcContext } = createGetKcContext<KcContextAccountType>();

export const { kcContext } = getKcContext() || {};

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
