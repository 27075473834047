import { GenericI18n } from "keycloakify/account/i18n/i18n";
import { CustomMessageKey } from "../messages/CustomMessages";
import { createContext, useContext, useMemo } from "react";

type MessageFunction<K, R> = (key: K, ...args: (string | undefined)[]) => R;

type KcMessagesContextProps = {
  msg: MessageFunction<CustomMessageKey, JSX.Element>;
  msgStr: MessageFunction<CustomMessageKey, string>;
  advancedMsg: MessageFunction<string, JSX.Element>;
  advancedMsgStr: MessageFunction<string, string>;
};

type KcMessagesContextProviderProps = {
  children: React.ReactNode;
  i18n: GenericI18n<CustomMessageKey>;
};

const KcMessagesContext = createContext<KcMessagesContextProps>({
  msg: () => <></>,
  msgStr: () => "",
  advancedMsg: () => <></>,
  advancedMsgStr: () => "",
});

export const KcMessagesContextProvider = ({
  children,
  i18n,
}: KcMessagesContextProviderProps) => {
  const defaultMsg = () => <></>;
  const defaultMsgStr = () => "";

  const msg = i18n.msg || defaultMsg;
  const msgStr = i18n.msgStr || defaultMsgStr;
  const advancedMsg = i18n.advancedMsg || defaultMsg;
  const advancedMsgStr = i18n.advancedMsgStr || defaultMsgStr;

  const value = useMemo(
    () => ({
      msg,
      msgStr,
      advancedMsg,
      advancedMsgStr,
    }),
    [msg, msgStr, advancedMsg, advancedMsgStr]
  );

  return (
    <KcMessagesContext.Provider value={value}>
      {children}
    </KcMessagesContext.Provider>
  );
};

export const useKcMessagesContext = () => useContext(KcMessagesContext);
