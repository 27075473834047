import { fr_messages as defaultFrMessages } from "@docaposte-agility/da-keycloakify";
import { CustomMessages } from "./CustomMessages";

export const fr_messages: CustomMessages = {
  ...defaultFrMessages,

  // HTML Titles
  accountLogHtmlTitle: "Journal d'activité des appareils",
  accountCredentialsHtmlTitle: "Authentification",
  basicAuthenticationHtmlTitle: "Authentification de base",
  twoFactorAuthenticationHtmlTitle: "Authentification à deux facteurs",
  linkedAccountsHtmlTitle: "Comptes liés",
  successSnackBarTitle: "Opération réussie",
  errorSnackBarTitle: "Une erreur est survenue",
  warningSnackBarTitle: "Attention",
  infoSnackBarTitle: "Notez que...",
  defaultSnackBarTitle: "Notification",

  // Labels
  totp: "TOTP",
  social: "Social",
  credentials: "Informations de connexion",
  civility: "Civilité",
  rowsPerPage: "Éléments par page",
  linkedAccountUsername: "Lié à l'utilisateur <b>{0}</b>",
  "otp-display-name": "Application d'authentification",
  "webauthn-display-name": "Clé de sécurité",
  language: "Langue",
  doLogout: "Déconnexion",

  // Actions
  viewProfile: "Mon profil",
  removeCredential: "Supprimer",
  endSessionSuccess: "Session terminée avec succès.",
  endSessionFailure:
    "Échec en essayant de terminer la session. Réessayez ultérieurement.",
  addOtp: "Ajouter une application d'authentification",
  addWebauthn: "Ajouter une clé de sécurité",
  addPassword: "Ajouter un mot de passe",
  noOtpConfigured: "Aucune application d'authentification configurée",
  noWebauthnConfigured: "Aucune clé de sécurité configurée",
  noAccountLinked: "Aucun compte lié",
  noPasswordConfigured: "Aucun mot de passe configuré",

  // Attributes
  createdDate: "Créé le {0}",
  myPersonalInformation: "Mes informations personnelles",
  myContactDetails: "Mes coordonnées",

  // Required actions
  UPDATE_PASSWORD: "Modifier le mot de passe",

  // Miscellaneous
  of: "sur",
};

export default fr_messages;
