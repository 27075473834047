import { Checkbox, SwitchProps } from "@mui/material";
import { KcContext, kcContext } from "../../kcContext";

export const Switch = (props: SwitchProps) => {
  const anyKcContext = kcContext as KcContext;
  if (anyKcContext.pageId === "login-update-password.ftl") {
    return <Checkbox {...props} />;
  }
  return <Switch {...props} />;
};
