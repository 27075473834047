import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import {
  LoginVerifyEmailProps,
  useComponentsContext,
  useGetCustomClassName,
  useKcMessagesContext,
} from "@docaposte-agility/da-keycloakify";

const LoginVerifyEmail = memo(
  ({ kcContext, ...props }: LoginVerifyEmailProps) => {
    const { url, user } = kcContext;
    const { msgStr, advancedMsgStr } = useKcMessagesContext();
    const { getLink, getTypography } = useComponentsContext();

    const { getClassName } = useGetCustomClassName({});

    return (
      <Stack
        sx={{ mt: 2 }}
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={5}
        className={getClassName("kcFormGroupClass")}
      >
        <Box className={getClassName("kcFormOptionsWrapperClass")}>
          {getTypography({
            align: "left",
            children: advancedMsgStr("emailVerifyInstruction1", user?.email),
          })}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          {getTypography({
            children: msgStr("emailVerifyInstruction2"),
          })}
          <Box sx={{ display: "flex" }}>
            {getTypography({
              children: (
                <>
                  {getLink({
                    href: url.loginAction,
                    id: "kc-restart-flow-link",
                    sx: { cursor: "pointer", display: "inline-block" },
                    children: msgStr("doClickHere"),
                  })}
                  &nbsp;{msgStr("emailVerifyInstruction3")}
                </>
              ),
            })}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          {getTypography({
            children: advancedMsgStr("loginVerifyEmailButton"),
          })}
          <Box sx={{ display: "flex" }}>
            {getTypography({
              children: (
                <>
                  {getLink({
                    id: "kc-restart-flow-link",
                    onClick: () => window.location.reload(),
                    sx: { cursor: "pointer", display: "inline-block" },
                    children: msgStr("doClickHere"),
                  })}
                  &nbsp;{advancedMsgStr("loginVerifyEmailButton2")}
                </>
              ),
            })}
          </Box>
        </Box>
      </Stack>
    );
  }
);

export default LoginVerifyEmail;
