import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { AppBar, Grid, IconButton, Paper } from "@mui/material";
import React, { memo } from "react";
import LogoContainer from "../logo/LogoContainer";
import { ProfileMenu } from "./ProfileMenu";
import { KcContext } from "../../../kcContext";
import { useHeaderLinks } from "../../../hooks/useHeaderLinks";
import PageLink from "./PageLink";

type MobileHeaderProps = {
  kcContext: KcContext;
};

const MobileHeader = memo(({ kcContext }: MobileHeaderProps) => {
  const { account, url } = kcContext;
  const [openNavigation, setOpenNavigation] = React.useState(false);

  const links = useHeaderLinks(kcContext);

  return (
    <Grid
      container
      component={AppBar}
      position="sticky"
      top={0}
      sx={{ background: "unset" }}
    >
      <Grid
        item
        container
        component={Paper}
        square
        display="flex"
        direction="row"
        alignItems="center"
        width="100%"
        padding="8px"
      >
        <Grid item xs={1}>
          <IconButton
            disableRipple
            onClick={() => setOpenNavigation(!openNavigation)}
            sx={{ color: "cobalt.bleuDigital100" }}
          >
            <CobaltIcon name="menu" />
          </IconButton>
        </Grid>
        <LogoContainer
          src={require("../../../assets/img/logo-certinomis.png")}
          alt="Logo certinomis"
          xs={6}
        />
        <ProfileMenu account={account} url={url} xs={5} />
      </Grid>
      {openNavigation && (
        <Grid
          item
          component={Paper}
          square
          display="flex"
          direction="column"
          position="relative"
        >
          {links
            .filter((link) => link.shouldRender)
            .map((link) => (
              <PageLink
                href={link.href}
                message={link.label}
                selected={link.selected}
              />
            ))}
        </Grid>
      )}
    </Grid>
  );
});

export default MobileHeader;
