import { Button } from "@mui/material";
import { CustomMessageKey } from "../../../messages/CustomMessages";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";
import { memo } from "react";

type PageLinkProps = {
  href: HTMLLinkElement["href"];
  message: CustomMessageKey;
  selected: boolean;
};

const PageLink = memo(({ href, message, selected }: PageLinkProps) => {
  const { msgStr } = useKcMessagesContext();

  return (
    <Button
      href={href}
      variant="text"
      disableRipple
      sx={{
        color: selected ? "cobalt.selected" : "cobalt.ultramarine",
      }}
    >
      {msgStr(message).toUpperCase()}
    </Button>
  );
});

export default PageLink;
