import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDeleteSession } from "../services";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { PageTitle } from "../components/custom/title/PageTitle";
import { PageProps } from "../types/pages";
import { useKcMessagesContext } from "../contexts/KcMessagesContext";
import { useSnackbar } from "notistack";

type SessionsProps = PageProps<KcContextCustom.Sessions>;

const Sessions = memo(({ kcContext }: SessionsProps) => {
  const { sessions } = kcContext.sessions;
  const { msgStr } = useKcMessagesContext();
  const { enqueueSnackbar } = useSnackbar();

  const { breakpoints } = useTheme();
  const matchesMdDown = useMediaQuery(breakpoints.down("md"));

  const [sessionList, setSessionList] = React.useState(sessions);

  const { mutate: deleteSession } = useDeleteSession({
    onSuccess: (response, id) => {
      setSessionList(sessionList.filter((s) => s.id !== id));
      enqueueSnackbar(msgStr("endSessionSuccess"), { variant: "success" });
    },
    onError: () => {
      enqueueSnackbar(msgStr("endSessionFailure"), {
        variant: "error",
      });
    },
  });

  const handleDelete = (id: string) => deleteSession(id);

  return (
    <Stack direction="column">
      <PageTitle message="sessionsHtmlTitle" />
      <TableContainer component={Paper} sx={{ overflow: "auto" }}>
        <Table
          sx={{ width: matchesMdDown ? "auto" : "100%", tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{msgStr("ip")}</TableCell>
              <TableCell>{msgStr("started")}</TableCell>
              <TableCell>{msgStr("lastAccess")}</TableCell>
              <TableCell>{msgStr("expires")}</TableCell>
              <TableCell>{msgStr("clients")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionList.map((session, idx) => {
              return (
                <TableRow hover key={idx}>
                  <TableCell>{session.ipAddress}</TableCell>
                  <TableCell>{session.started}</TableCell>
                  <TableCell>{session.lastAccess}</TableCell>
                  <TableCell>{session.expires}</TableCell>
                  <TableCell>{session.clients.join(", ")}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDelete(session.id)}
                      sx={{ color: "red" }}
                      disableRipple
                    >
                      <CobaltIcon name="trash-2" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
});

export default Sessions;
