import { KcDivider, KcDividerProps } from "@docaposte-agility/da-keycloakify";
import { colors } from "@docaposte-agility/da-design-system/";

export const Divider = (props: KcDividerProps) => {
  const { dividerProps, color } = props;

  return (
    <KcDivider
      {...props}
      dividerProps={{
        ...dividerProps,
        sx: {
          "&.MuiDivider-root::before": {
            borderTop: `1px solid ${color || colors.grey10}`,
          },
          "&.MuiDivider-root::after": {
            borderTop: `1px solid ${color || colors.grey10}`,
          },
        },
      }}
      typographyProps={{
        mx: 0,
      }}
    />
  );
};
