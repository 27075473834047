import {
  MainProfileTextField,
  PasswordProfileTextField as PasswordTextField,
  PasswordProfileTextFieldProps,
} from "@docaposte-agility/da-keycloakify";

export default function PasswordProfileTextField(
  props: PasswordProfileTextFieldProps
) {
  const { disabledEye, ...others } = props;

  if (props.attribute.name === "password-confirm") {
    return <MainProfileTextField type={"password"} {...others} />;
  }

  return <PasswordTextField {...props} />;
}
