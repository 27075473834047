import React, { memo } from "react";
import { KcContext } from "./kcContext";
import {
  ContextProvider,
  DaKcApp,
  getPage,
} from "@docaposte-agility/da-keycloakify";
import { Template } from "./pages/Template";
import { I18n, useI18n } from "./messages/i18n";
import LoginVerifyEmail from "./pages/LoginVerifyEmail";

const getCustomPage = (kcContext: KcContext, i18n: NonNullable<I18n>) => {
  switch (kcContext.pageId) {
    case "login-verify-email.ftl":
      return <LoginVerifyEmail {...{ kcContext, i18n }} />;
    default:
      return getPage(kcContext, i18n);
  }
};

export const App = memo(({ kcContext }: { kcContext: KcContext }) => {
  const i18n = useI18n({ kcContext });

  //NOTE: Locale not yet downloaded
  if (i18n === null) {
    return null;
  }

  const page = getCustomPage(kcContext, i18n);
  if (page !== undefined) {
    return (
      <ContextProvider
        i18n={i18n}
        kcContext={kcContext}
        customForm={{ mode: "onSubmit" }}
      >
        <Template {...{ kcContext, i18n }}>{page}</Template>;
      </ContextProvider>
    );
  }
  return <DaKcApp {...{ kcContext, i18n }} />;
});

export default App;
