import { useQuery } from "@tanstack/react-query";
import { AccountCredentialsType } from "../../types/credentials";
import { authenticatedRequest } from "../oidc";

const getCredentials = async (): Promise<AccountCredentialsType[]> => {
  const response = await authenticatedRequest("/account/credentials", {
    method: "GET",
  });

  return response.data;
};

export const useGetCredentials = () =>
  useQuery({
    queryKey: ["credentials"],
    queryFn: getCredentials,
  });

export default useGetCredentials;
