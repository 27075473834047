import React from "react";
import { App as LoginApp } from "./login/App";
import { App as AccountApp } from "./account/App";
import { kcContext as loginKcContext } from "./login/kcContext";
import { kcContext as accountKcContext } from "./account/kcContext";
import { CssBaseline } from "@mui/material";
import { WaitingScreen } from "@docaposte-agility/da-keycloakify";
import "./login/assets/css/login.css";

import { createRoot } from "react-dom/client";

interface Window {
  kcContext: { themeType: "login" | "account" };
}

declare let window: Window;

function getApp(kcContext: Window["kcContext"]) {
  if (kcContext === undefined) {
    return <WaitingScreen />;
  }

  switch (kcContext.themeType) {
    case "login":
      return (
        <>
          <CssBaseline />
          <LoginApp kcContext={loginKcContext!} />
        </>
      );
    case "account":
      return (
        <>
          <CssBaseline />
          <AccountApp kcContext={accountKcContext!} />
        </>
      );
  }
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<React.StrictMode>{getApp(window.kcContext)}</React.StrictMode>);
