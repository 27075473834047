import React, { memo } from "react";
import {
  Button,
  Grid,
  Link,
  RegularBreakpoints,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGetConfig } from "../../../services";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";

type LogoProps = RegularBreakpoints & {
  src: HTMLImageElement["src"];
  alt: HTMLImageElement["alt"];
  height?: number | string;
  width?: number | string;
};

const LogoContainer = memo(
  ({ src, alt, height, width, ...breakpoints }: LogoProps) => {
    const { msg } = useKcMessagesContext();
    const config = useGetConfig();
    const theme = useTheme();
    const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    const handleBack = () => {
      window.location.href = config.data?.logoUrl || document.referrer || "";
    };

    return (
      <Grid
        item
        margin="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...breakpoints}
      >
        <Grid
          component={Link}
          href={config.data?.logoUrl || document.referrer || ""}
          display="flex"
          alignItems="center"
          spacing={1}
          sx={{ textDecoration: "none" }}
        >
          {matchesSmDown && <CobaltIcon name="chevrons-left" />}
          <img
            src={src}
            alt={alt}
            loading="lazy"
            height={height ?? "auto"}
            width={width ?? "auto"}
            style={{
              maxWidth: matchesSmDown ? "30vw" : "10vw",
            }}
          />
        </Grid>
        {!matchesSmDown && (
          <Button
            variant="text"
            disableRipple
            onClick={handleBack}
            startIcon={<CobaltIcon name="chevron-left" />}
          >
            {msg("doBack")}
          </Button>
        )}
      </Grid>
    );
  }
);

export default LogoContainer;
