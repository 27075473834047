import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { PageProps } from "../types/pages";

type TotpProps = PageProps<KcContextCustom.Totp>;

const Totp = memo(({ kcContext }: TotpProps) => {
  const { url } = kcContext;

  React.useEffect(() => {
    window.location.href = url.passwordUrl;
  }, [url.passwordUrl]);

  return <></>;
});

export default Totp;
